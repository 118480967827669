<template>
    <div>
        <!-- <img v-if="computedQuestionList.length<0" class="loading" src="@/assets/images/loading.gif" alt='Loading'> -->
        
        <h5 class="questionPagingHeading pb-2">List of Questions</h5>
         <div class="questionPagingHeading pt-1 text-center">
            <b-button-group>
            <b-button @click="filterList('all')" :pressed="type=='all'" variant="outline-info">All</b-button>
            <b-button @click="filterList('weekly')" :pressed="type=='weekly'" variant="outline-info">Weekly P   <i class="fa fa-star"></i></b-button>
            <b-button @click="filterList('monthly')" :pressed="type=='monthly'" variant="outline-info">{{month}} P <i class="fa fa-star"></i></b-button>
            <b-button @click="filterList('exam')" :pressed="type=='exam'" variant="outline-info">Exam Q <i class="fa fa-star"></i></b-button>
            </b-button-group>
        </div>
        <b-list-group v-if="computedQuestionList.length>0">
            <b-overlay :show="showOverLap"> 
            <b-list-group-item v-for="(question) in computedQuestionList" :key="question[questionId]" class="d-flex justify-content-between align-items-center" :id="'id'+question[questionId]" @click="getQuestionDetail(question[questionId])" >
                    
                    {{question["title"]}}
                    <div class="text-right" v-if="userType.toLowerCase()=='premium'">
                         <b-badge class="listBadge" v-if="question.predicted_ind == 'Y'" variant="success" pill>Predict</b-badge>
                        <b-badge class="listBadge ml-3" v-if="question.exam_ind == 'Y'" variant="warning" pill>Exam Q</b-badge>
                    </div>
                   
               
                </b-list-group-item>
                <template #overlay>
                    <div class="text-center">
                    <b-icon icon="lock" font-scale="3" animation="cylon"></b-icon>
                    <h4 class="mt-2" id="cancel-label">For premium users</h4>
                    </div>
                    </template>
            </b-overlay>
            </b-list-group>
        <p v-else class="text-center">
            <!-- <strong>Loading.....</strong> -->
            <img class="loadingImg" src="@/assets/images/loading.gif"/>
        </p>
            <p class="questionPaging mt-10">
                <b-button :disabled="listStart<=0" @click="changeList('prev')" variant="link mr-30" >Prev</b-button>
            Questions <strong> {{listStart+1}} </strong> to <strong>{{listEnd}} </strong>
            <b-button @click="changeList('next')"  :disabled="listEnd>=currentQuestionList.length"  variant="link ml-30">Next</b-button>
            </p>
    </div>
</template>
<script>
import $ from "jquery";
import {bus} from '../../main'
export default {
    props:{
        questionLists:{
                required: true
            },
            questionId:{
                required: false,
                default:'question_id'
            },
            popup:{
                required: false,
                default:false
            },
            parentFunctionCalling:{
                required:false
            },
            
            
    },
    data(){
        return{
            listStart:0,
            listEnd:10,
            interval:10,
            load:false,
            currentSelectedItem:0,
            type:'all',
            month:'',
            currentQuestionList:this.questionLists,
            newId:0,
            isLoaded:false,
            userType:'Free',
            showOverLap:false
        }
    },
    computed:{
        computedQuestionList(){
                return this.sliceReturn()
        }
    },
    methods:{
        async changeList(type){
            console.log("type")
            if(type == 'prev')
            {
                this.listStart =(this.listStart-this.interval>=0)?this.listStart-this.interval : 0;
                this.listEnd =(this.listEnd>=this.interval) ?this.listEnd-this.interval: this.listEnd
                if(this.listStart == 0) this.listEnd = this.interval
            }
            else{
                debugger
                
                this.listEnd = (this.listEnd+this.interval <= this.currentQuestionList.length)? this.listEnd+this.interval : this.currentQuestionList.length;
                this.listStart= (this.listStart + this.interval < this.currentQuestionList.length)? this.listStart+this.interval: this.listEnd-this.interval;
                if(this.listEnd == this.currentQuestionList.length){
                     this.listStart= (this.currentQuestionList.length> this.interval)? this.currentQuestionList.length- this.interval: 0
                }
            }
            // if(change=='next')
            // {
            //     $('.list-group div:first-child').click()
            // }
        },
        filterList(pressed){
            if(this.type == pressed) return
            // send 0 id remove selected question
            this.$parent.getQuestionDetail(0)
            this.listStart=0
            this.listEnd=10
            this.interval=10
            this.type=pressed
        },
        getQuestionDetail(id){
            //console.log(id)
            this.currentSelectedItem = id
            $('.selectedItem').removeClass('selectedItem')
            if(!this.popup)
            {
                this.$parent.getQuestionDetail(id)
                $('#id'+id).addClass('selectedItem')
                this.currentSelectedItem = id
            }
            else{
                this.parentFunctionCalling(id)
                //this.$refs['search-Result'].hide()
            }
            document.getElementById("mySidenav").style.width = "0";
        },
        async moveQuestion(change){
            if(change=='prev'){
                if(this.currentSelectedItem == this.listStart+1) 
                { 
                    ////debugger
                    await this.changeList('prev')
                    $('.list-group div:last-child').click()
                }
                else{
                    const id='#id'+this.currentSelectedItem
                    $(id).prev().click()
                }
                
            }
            else{
                if(this.currentSelectedItem == this.listEnd) 
                {
                    await this.changeList('next')
                    $('.list-group div:first-child').click()
                }
                else{
                    const id='#id'+this.currentSelectedItem
                    $(id).next().click()
                }
            }
        },
        async changeQuestion(change){
          //  console.log(change);
            if(change=='prev'){
                if(this.currentSelectedItem == this.listStart+1) 
                { 
                    ////debugger
                    await this.changeList('prev')
                    $('.list-group div:last-child').click()
                }
                else if($('.list-group div:first-child > div:first-child')[0].id==('id'+this.currentSelectedItem)){
                    await this.changeList('prev').then(()=>{
                        $('.list-group div:last-child > div:last-child').click()

                    })
                }
                else{
                    $('#id'+this.currentSelectedItem).prev().click()
                }
            }
            else{
                console.log(change);
                if(this.currentSelectedItem == this.listEnd) 
                {
                    await this.changeList('next')
                    $('.list-group div:first-child').click()
                }
                else if($('.list-group div:last-child > div:last-child')[0].id==('id'+this.currentSelectedItem)){
                    console.log(change);
                    await this.changeList('next').then(()=>{
                        $('.list-group div:first-child > div:first-child').click()
                    })
                }
                else{
                    $('#id'+this.currentSelectedItem).next().click()
                }
            }
        },
        sliceReturn(){
            this.currentQuestionList = null
            this.currentQuestionList=this.questionLists
            if(this.type=='monthly'){
                //console.log(this.type)
                const today = new Date()
                const oldDate = new Date(today)
                oldDate.setDate(oldDate.getDate() - 30)
                this.currentQuestionList = this.questionLists.filter((item)=>{
                    return item.predicted_ind == 'Y' 
                    //& new Date(item.predicted_date) >= oldDate
                })
            }
            else if(this.type=='weekly'){
                //console.log(this.type)
                const today = new Date()
                const oldDate = new Date(today)
                oldDate.setDate(oldDate.getDate() - 14)
                if(this.questionLists!=null)
                this.currentQuestionList = this.questionLists.filter((item)=>{
                    return item.predicted_ind == 'Y' 
                    //& new Date(item.predicted_date) >= oldDate
                })
            }
            else if(this.type=='exam'){
                //console.log(this.type)
                if(this.questionLists!=null)
                this.currentQuestionList = this.questionLists.filter((item)=>{
                    return item.exam_ind == 'Y'
                })
            }
            // else{
            //     if(this.userType=="Free"){
            //         if(this.questionLists!=null)
            //         this.currentQuestionList = this.questionLists.filter((item)=>{
            //             return (item.exam_ind != 'Y' && item.predicted_ind != 'Y' ) 
            //         })
            //     }
            // }

            if(this.type!="all" & this.userType.toLowerCase()!='premium'){
                this.showOverLap=true;
            }
            else{
                this.showOverLap=false;
            }
            if(this.currentQuestionList == null) return this.isLoaded=true
            if(this.currentQuestionList.length>0){
                this.isLoaded=true
                return this.currentQuestionList.slice(this.listStart,this.listEnd);
            }
            else{
                this.isLoaded=true
                return this.currentQuestionList;
            }
        },
        getIdFromOtherComp(id){
                    setTimeout(() => {  
                        this.newId=id
                    }, 3000);
                    //console.log(this.questionLists)
                        
        }
    },
    watch: {
    // whenever question changes, this function will run
    newId: function () {
        if(this.isLoaded & this.newId!=0){
             //console.log(this.isLoaded+'-'+this.newId)
                this.getQuestionDetail(this.newId)
        }
    },
  },
    created(){
        this.load =(this.currentQuestionList.length<=0)?true:false
        var userdata =this.$ls.get('user');
        this.userType=userdata.user_type;
        const today = new Date();
        this.month=today.toLocaleString('default', { month: 'short' })
        bus.$on('getQuestionFromComponents', this.getIdFromOtherComp);
    }
}
</script>
<style>
.fa-star{
    color: #e00303;
    padding-left: 5px;;
    /* border: 1px solid yellow; */
}
.listBadge
{
    padding: 5px 5px!important;
    border-radius: 5px!important;
}
</style>
<style scoped>
/* .loadingImg{
    display: block!important;
    width: 100%!important;
} */
.questionPaging
{
  text-align: center;
padding-top: 10px;
border: 1px solid #eae5e5;
    border-top-color: rgb(234, 229, 229);
    border-top-style: solid;
    border-top-width: 1px;
border-top: 0px;
border-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
border-top-right-radius: 0px;
border-top-left-radius: 0px;
color: #f23276;
    font-size: 15px;
    background: #eef1f8;
}
.questionPaging strong{
  color: #7d7b7b;
}
.questionPaging .btn-link {
    font-weight: 600;
    color: #0f51d4;
}
.questionPagingHeading
{
text-align: center;
padding: 10px;
border: 1px solid #eae5e5;
border-bottom-color: rgb(247, 247, 247);
border-bottom-style: solid;
border-bottom-width: 1px;
color: #f23276;
border-bottom: 0px;
border-top-left-radius: 3px;
border-top-right-radius: 3px;
background: #f8f8f9;
font-weight: 600;
}
.questionPagingHeading.text-center
{
    border-top: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.questionPagingHeading.text-center .btn{
    font-size: 13px!important;
}
.questionPagingHeading.text-center .btn.active{
    font-weight: 600;
    box-shadow:none!important
}
.questionPagingHeading.text-center .btn:focus{
     box-shadow:none!important
}
.loading
{
    position: relative;
width: 100%;
padding: 20%;
}
.selectedItem
{
    color: #1f4780;
    font-weight: 600;
    background: #eef1f8;
}
.selectedItem .badge:empty
{
    display: block;
}
.selectedItem .badge-pill {
    padding-right: 1.6em;
    padding-left: 1.6em;
    border-radius: 1rem;
}
</style>